.flexbox {
    display: flex;
    align-items: center;
}

.incentive {
    position: relative;
    border: 1px solid black;
    align-items: stretch;
}

.incentiveBack {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    z-index: -2;
}

.incentiveProgress {
    position: absolute;
    height: 100%;
    background: rgba(201, 117, 255, 0.5);
    z-index: -1;
}

.incentiveSelectButton {
    padding: 0 0.5em;
}

.incentiveTitle {
    font-size: 1.3em;
    font-weight: 500;
}

.incentiveDescription {
    font-size: 1.1em;
}

.incentiveAuthor {
    font-size: 1.1em;
    font-weight: 400;
}

.incentiveVotes {
    font-size: 0.8em;
}

.incentiveImage {
    height: 4em;
    padding-right: 0.5em;
}

.incentiveLink {
    color: #555;
    background: rgba(128, 128, 128, 0.3);
    padding: 0 1em;
}

.orderSummary {
    font-size: 1.2em;
    padding: 2em;
}

.paypalButtons {
    width: 700px;
    min-width: 40vw;
    max-width: 100vw;
}

.red {
    color: darkred;
}

.browsingContainer {
    width: 600px;
    min-width: 40vw;
    max-width: 100vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5vh;
}

.successContainer {
    width: 600px;
    min-width: 40vw;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7vw;
    padding: 2em;
    border-radius: 2em;
    background: rgba(255, 255, 255, 0.5);
    font-size: 1.3em;
    text-align: center;
}

.errorContainer {
    width: 600px;
    min-width: 40vw;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7vw;
    padding: 2em;
    border: 5px solid red;
    border-radius: 2em;
    background: rgba(255, 128, 128, 0.5);
    font-size: 1.3em;
    text-align: center;
}


.browsingContainer input {
    font-size: 1em;
}

.browsingContainer textarea {
    font-size: 1em;
}

.browsingContainer button {
    font-size: 1em;
}

.backButton {
    font-size: 1em;
}
