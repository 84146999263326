.container {
    margin-bottom: 1em;
}

.upperClosed {
    background-color: #FBD4FB;
    padding: 0.5em;
    border-radius: 0.5em;
    font-size: larger;
}

.upperOpen {
    background-color: #FBD4FB;
    padding: 0.5em;
    border-radius: 0.5em 0.5em 0 0;
    border-bottom: 2px solid #DBB4DB;
    font-size: larger;
}

.lower {
    background-color: #FBD4FB;
    padding: 0.5em;
    border-radius: 0 0 0.5em 0.5em;
    transition: 0.4s;
}
