.contentStrip {
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 0;
    min-height: 100vh;
    font-size: 1.3em;
    background-color: white;
}

img.banner {
    width: 100%;
}

.navBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em 1em;
    margin: 1em 0;
}

a.navButton {
    display: inline-block;
    color: black;
    border-radius: 0.6em;
    padding: 0.5em 0.7em;
    background-color: #FBD4FB;
    min-width: 5em;
    text-align: center;
}

.main {
    font-size: 0.8em;
}

@media (min-width: 500px) {
    .main {
        padding: 0 2em;
    }
}

.footer {
    margin-top: 1em;
    font-size: 0.7em;
    text-align: center;
}

.socials {
    display: inline-block;
    height: 25px;
}

.socials a img, .socials a svg {
    height: 100%;
    margin: 10px;
}
