body {
    margin: 0;
    font-family: Montserrat, Calibri, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media all and (min-width: 2000px) {
    body {
        font-size: 1vw;
    }
}

@media all and (max-width: 1999px) {
    body {
        font-size: 18px;
    }
}

a {
    text-decoration: none;
}
