.bitsInteraction {
	display: flex;
	align-items: center;
	padding: 1em 0;
}

.caterpillarImage {
	width: 10em;
	min-width: 150px;
	margin-right: 1em;
}

.interactionImage {
	width: 16em;
	min-width: 150px;
	margin-right: 1em;
}

.chatCode {
	display: inline-block;
	border: 2px solid black;
	border-radius: 0.3em;
	padding: 0 0.3em;
	margin: 0.5em 0 0.2em 0;
}
