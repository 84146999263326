.container {
    border: 2px solid #093d11;
    border-radius: 6px;
    padding: 1em;
    background: #dbe5d8;
}

.successContainer {
    border: 2px solid #093d11;
    border-radius: 6px;
    padding: 1em;
    background: #ABFB84;
    text-align: center;
}

.errorContainer {
    border: 2px solid red;
    border-radius: 6px;
    padding: 1em;
    background: rgba(255, 128, 128, 0.5);
    text-align: center;
}

.counter {
    display: inline-flex;
    align-items: stretch;
    margin-bottom: 0.2em;
}

.counterText {
    width: 30px;
}

.counterButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.counterPlusMinus {
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border: 1px solid black;
    /* disable text selection */
    user-select: none;
    -ms-user-select: none; /* IE 10 and IE 11 */
    -webkit-user-select: none; /* Safari */
}

.yesNoButton {
    display: inline-block;
    background: #FFF;
    border: 1px solid black;
    border-radius: 3px;
    padding: 0 3px;
    /* disable text selection */
    user-select: none;
    -ms-user-select: none; /* IE 10 and IE 11 */
    -webkit-user-select: none; /* Safari */
}

.label {
    margin-top: 1em;
}

.subLabel {
    margin-top: 0.3em;
}

.textarea {
    width: 50em;
    max-width: 100%;
}

.formErrors {
    color: red;
    margin-top: 1em;
}

.formErrors ul {
    font-size: 0.8em;
    margin: 0.5em;
}

.terms {
    font-size: 0.8em;
    background: white;
    border: 1px solid black;
    margin: 0.5em 0;
    padding: 1em;
}

.paypalButtons {
    width: 360px;
    min-width: 35vw;
    margin-top: 1em;
}
