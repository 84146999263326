.donationbar {
    width: 1920px;
    height: 80px;
    position: relative;
    font-family: Montserrat, Calibri, sans-serif;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD, 0 0 5px #FD83FD;
}

.progressbar {
    position: absolute;
    height: 100%;
    background: rgba(255, 0, 255, 0.25);
    z-index: -1;
}

.container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
}

.inline {
    display: inline-block;
}
