.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;

}

.livestreamButton {
    font-size: 1.2em;
    color: black;
    border-radius: 0.4em;
    padding: 0.4em 0.4em;
    margin: 0.4em;
    background-color: #FBD4FB;
    background-color: #d1f5bf;
    min-width: 5em;
    text-align: center;
}

.countdown {
    font-family: monospace;
    font-size: 8vw;
    display: flex;
    justify-content: center;
}

.twitchPlayer {
    width: 48vw;
    height: 27vw;
    min-width: 400px;
    min-height: 300px;
    max-width: 900px;
    max-height: 675px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;
}
